import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectDrivers } from '@/features/domain/driver'
import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { naturalSort } from '@/server-data'

import { useTexts } from './useTexts'

interface AssignmentInfo {
  id: string
  driverLabel: string
  driverId: string
  vehicleLabel: string
  hasDevice: boolean
}

export function useAssignmentsInfo(assignments: uui.domain.client.rm.DriverAssignments) {
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const vehicles = useSelector(selectVehicles)
  const drivers = useSelector(selectDrivers)
  const texts = useTexts()

  return useMemo(() => {
    return Object.keys(vehiclesByVehicleId)
      .reduce<AssignmentInfo[]>((acc, vehicleId) => {
        const driverId = assignments[vehicleId] ?? ''
        const driver = drivers[driverId]

        const unifiedId = vehiclesByVehicleId[vehicleId] ?? ''
        const vehicle = vehicles[unifiedId]

        const hasDevice =
          vehicle.isPhysicalDevice || (vehicle.hasRoutingLicense && !!vehicle.vehicle.gpsDeviceId)

        acc.push({
          id: `${vehicleId}-${driverId}`,
          driverLabel: driver?.driver.name ?? texts.externalDriver,
          driverId,
          vehicleLabel: vehicle.hasRoutingLicense ? vehicle.vehicle.externalId : '',
          hasDevice,
        })

        return acc
      }, [])
      .sort((a, b) => naturalSort(a.vehicleLabel, b.vehicleLabel))
  }, [drivers, vehiclesByVehicleId, vehicles, texts, assignments])
}
