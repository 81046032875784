import { CSSProperties, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { format, isSameDay } from 'date-fns/esm'

import { CalendarAlt, LeftFilledArrow, RightFilledArrow } from '@/icons'
import { useAppDispatch } from '@/store'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { changeCalendarRange } from '@/features/domain/ui'

import { DropdownMenu, MenuTrigger } from '@/components/DropdownMenu'
import { Tooltip } from '@/components/primitives/Tooltip'
import { IconButton } from '@/components/primitives/buttons/IconButton'
import { FormatDateInterval } from '@/components/smartUtils/time/FormatDateInterval'

import { useExportRoutesFromCalendarModal } from '../../../../../modals/ExportRoutesFromCalendarModal'
import { TerritoryCalendar } from './components/TerritoryCalendar'
import { useTexts } from './useTexts'
import { useData } from 'apps/route-manager/src/hooks/useCalendarDays'

const roundedArrowStyle: CSSProperties = {
  position: 'relative',
  padding: 8,
  width: 28,
  height: 28,
  borderRadius: '50%',
}

const removeMargin: CSSProperties = {
  marginLeft: '0 !important', // Remove default margin
}

const MenuTriggerContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  minWidth: 185, // (Wednesday - Mon XX)
}))

export function CalendarDropdownMenu() {
  const [open, setOpen] = useState<boolean>(false)
  const [dates, setDates] = useState<string[]>([])
  const [multiTerritory, setMultiterritory] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { start, end } = useSelector(selectCalendarRangeInterval)
  const { show: showExportRoutesFromCalendarModal, Modal: ExportRoutesFromCalendarModal } =
    useExportRoutesFromCalendarModal()
  const texts = useTexts()
  const data = useData()

  const isSingleDay = useMemo(() => start.getTime() === end.getTime(), [start, end])

  const isLeftArrowDisabled = useMemo(
    () => data === 'not-ready' || (isSingleDay && data.minDate && isSameDay(data.minDate, start)),
    [isSingleDay, data, start],
  )

  const isRightArrowDisabled = useMemo(
    () => data === 'not-ready' || (isSingleDay && data.maxDate && isSameDay(data.maxDate, start)),
    [isSingleDay, data, start],
  )

  const arrowVisibilityStyle: CSSProperties = {
    visibility: isSingleDay ? 'visible' : 'hidden',
  }

  const handleOnClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleArrowClick = useCallback(
    async (direction: 'prev' | 'next') => {
      if (data === 'not-ready') return

      const newDate = new Date(start)
      newDate.setDate(direction === 'prev' ? start.getDate() - 1 : start.getDate() + 1)
      const newDateString = format(newDate, 'yyyyMMdd')
      await dispatch(
        changeCalendarRange({
          minDate: newDateString,
          maxDate: newDateString,
        }),
      )
    },
    [start, dispatch, data],
  )

  return (
    <>
      <Tooltip
        placement="bottom"
        title={texts.previous}
        disabled={!isSingleDay || isLeftArrowDisabled}
      >
        <IconButton
          style={{ ...roundedArrowStyle, ...arrowVisibilityStyle }}
          onClick={() => handleArrowClick('prev')}
          data-testid="header__prev-button"
          disabled={isLeftArrowDisabled}
        >
          <LeftFilledArrow size={8} color="$pureWhite" />
        </IconButton>
      </Tooltip>
      <Box sx={removeMargin}>
        <DropdownMenu
          open={open}
          setOpen={setOpen}
          autoWidth
          enableHoverEffect
          trigger={
            <Tooltip placement="bottom" title={texts.calendar}>
              <MenuTriggerContainer>
                <MenuTrigger
                  menuOpen={open}
                  testid="header__calendar-menu-trigger"
                  className="pendo-bluebar__calendar-menu"
                  Icon={<CalendarAlt size={19} marginRight={10} />}
                  showDownArrow={false}
                >
                  <FormatDateInterval start={start} end={end} />
                </MenuTrigger>
              </MenuTriggerContainer>
            </Tooltip>
          }
        >
          <TerritoryCalendar
            onClose={handleOnClose}
            setExportDates={setDates}
            setMultiterritory={setMultiterritory}
            showExportRoutesFromCalendarModal={showExportRoutesFromCalendarModal}
          />
        </DropdownMenu>
      </Box>
      <Tooltip
        placement="bottom"
        title={texts.next}
        containerStyle={removeMargin}
        disabled={!isSingleDay || isRightArrowDisabled}
      >
        <IconButton
          style={{ ...roundedArrowStyle, ...arrowVisibilityStyle }}
          onClick={() => handleArrowClick('next')}
          data-testid="header__next-button"
          disabled={isRightArrowDisabled}
        >
          <RightFilledArrow size={8} color="$pureWhite" />
        </IconButton>
      </Tooltip>
      <ExportRoutesFromCalendarModal dates={dates} multiTerritory={multiTerritory} />
    </>
  )
}
